import axios from 'axios';

const apiClient = axios.create({
   // baseURL: 'http://127.0.0.1:8000/api', // Backend URL'ini buraya ekle
   //  baseURL: 'https://teleferik.duguncu.com/api', // Backend URL'ini buraya ekle
   baseURL: 'https://fpapi.duguncu.com/api', // Backend URL'ini buraya ekle
   headers: {
      'Content-Type': 'application/json'
   }
});

apiClient.interceptors.request.use(
   (config) => {
      config.headers['Accept'] = 'application/x.psis.v3+json';
      const token = localStorage.getItem('token');
      if (token) {
         config.headers['Authorization'] = `Bearer ${token}`;
      }
      const company = localStorage.getItem('company_id');
      if (company) {
         config.headers['X-Company'] = company;
      }
      const sube = localStorage.getItem('sube_id');
      if (sube) {
         config.headers['X-Sube'] = sube;
      }
      const ch = localStorage.getItem('companyhash');
      if (ch) {
         config.headers['X-CompanyHash'] = ch;
      }
      return config;
   },
   (error) => {
      return Promise.reject(error);
   }
);

apiClient.interceptors.response.use(
   (response) => {
      return response;
   },
   (error) => {
      if (error.response && error.response.status === 401) {
         localStorage.removeItem('token');
         window.location.href = '/auth/login';
      } else if (error.response.status === 419) {
         alert('Zamanaşımı oluştu. Sayfa yeniden yüklenecektir.');
         window.location.reload();
      } else if (error.response.status === 403) {
         alert('Bu işlem için yetkiniz yok');
      }
      return Promise.reject(error);
   }
);

export const apiService = {
   get(resource, params) {
      return apiClient.get(resource, { params });
   },
   post(resource, data) {
      return apiClient.post(resource, data);
   },
   put(resource, data) {
      return apiClient.put(resource, data);
   },
   delete(resource) {
      return apiClient.delete(resource);
   }
};
