import { createRouter, createWebHistory } from 'vue-router';


const router = createRouter({
   history: createWebHistory(),
   routes: [

      {
         path: '/',
         redirect: '/fptest'
      },
      {
         path: '/fptest',
         name: 'fptest',
         component: () => import('@/views/FpTest.vue')
      }
   ]
});

export default router;
