import { createApp } from 'vue'
import App from './App.vue'
import { apiService } from './services/apiService';

import 'bootstrap';

import './assets/css/style.bundle.css'
import './assets/plugins/global/plugins.bundle.css'

import 'datatables.net-bs5'; // DataTables JS

import '@/assets/plugins/custom/datatables/datatables.bundle.css';
import router from './router';
import helpers from './services/helpers';

// Axios'u global olarak ayarlayın


// Vue uygulamasını oluşturun


const app = createApp(App, {
   provide: {
      helpers: helpers
   }
});
app.config.globalProperties.$helpers = helpers;
app.config.globalProperties.$apiService = apiService;

// Axios'u global olarak Vue'da kullanabilmek için

app.use(router);
app.mount('#app');
