import { apiService } from './apiService';

export default {
   fetchFromApi: async function (_res = '', _param = {}, _pm) {
      try {

         const response = await apiService.get(_res, _param);
         _pm.value = response.data;
      } catch (error) {
         console.error('Error fetching data', error);
      }
   },
   capitalizeWords: function (arr) {
      return arr.map((element) => {
         return element.charAt(0).toUpperCase() + element.substring(1).toLowerCase();
      });
   },
   makeReadable: function (key) {
      return this.capitalizeWords(key.replaceAll('_', ' ').split(' ')).join(' ');
   },
   formatDateTime: function (dt) {
      if (dt == undefined) return '';
      let xd = new Date(dt).toLocaleDateString('tr-TR');
      if (xd == 'Invalid Date') return '';
      else return xd;
   },
   formattedDate(dt) {
      const date = new Date(dt);
      return date.toLocaleDateString('tr-TR'); // Türkçe tarih formatı
   },
   formattedTime(dt) {
      const date = new Date(dt);
      return date.toLocaleTimeString('tr-TR', { hour: '2-digit', minute: '2-digit', second: '2-digit' }); // Türkçe saat formatı
   },
   isUUID: function (uuid) {

      const uuidv2 = '^[0-9A-F]{8}-[0-9A-F]{4}-[2][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$';
      const uuidv5 = '^[0-9A-F]{8}-[0-9A-F]{4}-[5][0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$';
      let s = "" + uuid;

      // s = s.match();
      if (s.match(uuidv2) !== null)
         return true;
      else if (s.match(uuidv5) !== null)
         return true;
      else if (s.match('^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$') !== null)
         return true;
      else return false;

   },

   getSVGImage: function (name) {
      return require(`@/assets/${name}.svg`); // SVG dosyasını dinamik olarak çağırıyoruz
   },
   GridMenuItems: function (ary, rowData) {
      let yeni = [];

      ary.forEach((element) => {
         yeni.push({
            label: element.label,
            icon: element.icon,
            command: element.command,
            disabled: eval(element.disabled, rowData),
            visible: eval(element.visible ? element.visible : true, rowData)
            //  disabled: eval("((rowData.bittarihi !== null) || (rowData.isiptal != '0'))", rowData) // active durumuna göre disable
         });
      });
      return yeni;
   }
};
